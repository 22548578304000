<template>
  <div class="wrapper">
    <b-row class="align-items-center">
      <b-col
        md="10"
        class="d-flex flex-column"
      >
        <label class="font-14">Wallet address</label>
        <strong>{{ pingPongWallet }}</strong>
      </b-col>

      <b-col
        md="2"
        class="text-right"
      >
        <b-img
          class="mr-2 tool"
          fluid
          alt="img"
          :src="require('@/assets/images/pages/payment/ic-copy.svg')"
          @click="handleCopy"
        />

        <b-img
          v-if="barCode"
          id="popover-target-1"
          class="tool"
          fluid
          alt="img"
          :src="require('@/assets/images/pages/payment/ic-barcode.svg')"
        />

        <b-popover
          target="popover-target-1"
          triggers="hover"
          placement="top"
        >
          <div class="qbarcode">
            <p class="wallet-id text-center">
              {{ pingPongWallet }}
            </p>
            <b-img
              fluid
              alt="img"
              :src="barCode"
            />
          </div>
        </b-popover>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BPopover,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BPopover,
  },

  mixins: [toastification],

  props: {
    wallet: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },

  computed: {
    pingPongWallet() {
      return this.wallet.add
    },

    barCode() {
      return process.env.VUE_APP_PAYONEER_BAR_CODE
    },
  },

  methods: {
    handleCopy() {
      window.navigator.clipboard.writeText(this.pingPongWallet)
        .then(() => {
          this.toastSuccess(this.$t('payment.contentCopiedId'))
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  padding: 16px;
}
.input {
  border: none;
}

.tool {
  cursor: pointer;
}
.qbarcode {
  width: 147px;
  margin-bottom: 14px;
}

.wallet-id {
  font-size: 12px;
  line-height: 1.2;
}
</style>
