<template>
  <div class="summary-container mt-2">
    <h4
      id="title-summary-pingpong-card"
      data-testid="title-summary-pingpong-card"
      class="title-summary"
    >
      {{ $t('addFundPage.creditCardPayment.textSummary') }}
    </h4>
    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-amount-pingpong-card"
        data-testid="title-amount-pingpong-card"
        class="font-14 text-placeholder"
      >
        {{ $t('balance.textAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="pingpong-card-current-amount"
        data-testid="pingpong-card-current-amount"
      >
        {{ localStringFormat(amount) }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-pingpong-card-method"
        data-testid="title-pingpong-card-method"
        class="font-14 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
      </div>
      <div
        v-if="amount"
        id="pingpong-card-method"
        data-testid="pingpong-card-method"
      >
        <img
          :src="require('@/assets/images/pages/payment/ic-pingpong-small.png')"
          width="21px"
          alt="PingPong"
          class="mr-0"
        >
        PingPong
      </div>
      <strong v-else>
        --
      </strong>
    </div>

    <div
      v-if="percentFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-service-fee-pingpong-card"
        data-testid="title-service-fee-pingpong-card"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        <small
          id="percent-service-pingpong-card-fee"
          data-testid="percent-service-pingpong-card-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="pingpong-card-service-charge"
          data-testid="pingpong-card-service-charge"
          class="d-flex align-items-center"
        >
          <strong
            id="pingpong-card-current-service-charge"
            data-testid="pingpong-card-current-service-charge"
            class="text-primary"
          >{{ serviceCharge }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="methodFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-method-fee-pingpong"
        data-testid="title-method-fee-pingpong"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.paypal.textPaymentFee', {payment:"PingPong"}) }}
        <small
          id="percent-po-fee"
          data-testid="percent-po-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${methodFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="summary-current-po-fee"
            data-testid="summary-current-po-fee"
            class="text-primary"
          >{{ pingPongFee }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="taxRate"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-tax-fee-pingpong"
        data-testid="title-tax-fee-pingpong"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textTax') }}
        <small
          id="percent-tax-po-fee"
          data-testid="percent-tax-po-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTaxTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="summary-current-tax-po-fee"
          data-testid="summary-current-tax-po-fee"
          class="d-flex align-items-center"
        >
          <strong
            id="current-tax-po-fee"
            data-testid="current-tax-po-fee"
            class="text-primary"
          >{{ tax }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="currentTip > 0"
      class="d-flex align-items-center justify-content-between"
    >
      <div
        id="title-tip-wise"
        data-testid="title-tip-wise"
        class="font-14 text-placeholder"
      >
        Tip
        <small
          id="percent-tip-wise-fee"
          data-testid="percent-tip-wise-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTipTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="wise-tip-amount"
          data-testid="wise-tip-amount"
          class="d-flex align-items-center"
        >
          <strong
            id="current-wise-tip-amount"
            data-testid="current-wise-tip-amount"
            class="text-primary"
          >{{ amountTipTxt }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between pb-3">
      <div
        id="title-total-amount-pingpong-card"
        data-testid="title-total-amount-pingpong-card"
        class="font-24 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textTotalAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="current-pingpong-card-total-amount"
        data-testid="current-pingpong-card-total-amount"
        class="font-24 total-amount"
      >
        {{ totalAmount }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
  </div>
</template>

<script>
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'

// plugins
import { createNamespacedHelpers } from 'vuex'
import envMixin from '@/mixins/envMixin'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {

  mixins: [paymentMixin, generalConfigsMixin, numberFormatMixin, envMixin],

  props: {
    amount: {
      type: Number,
      required: true,
    },
    amountTip: {
      type: Number,
      required: true,
    },
    currentTip: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGettersAuth(['user']),

    percentFee() {
      return this.user?.data?.plans?.transferFee?.pingPongTicket || 0
    },

    percentFeeTxt() {
      return `${(this.percentFee * 100).toFixed(2)}%`
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.pingPongTicketGate || 0
    },

    methodFeeTxt() {
      return `${(this.methodFee * 100).toFixed(2)}%`
    },

    pingPongFee() {
      return this.amountFormat(this.methodFee * this.amount).toLocaleString('en-US')
    },

    percentTaxTxt() {
      return `${(this.taxRate * 100).toFixed(2)}%`
    },

    tax() {
      return this.amountFormat(this.taxRate * this.amount).toLocaleString('en-US')
    },

    percentTipTxt() {
      return `${(this.currentTip).toFixed(2)}%`
    },

    amountTipTxt() {
      return this.amountFormat(this.amountTip).toLocaleString('en-US')
    },

    totalAmount() {
      const fee = this.amountFormat(this.percentFee * this.amount)
      const tax = this.amountFormat(this.taxRate * this.amount)
      const methodFee = this.amountFormat(this.methodFee * this.amount)
      return (this.amount + fee + tax + methodFee + this.amountTip).toLocaleString('en-US')
    },

    serviceCharge() {
      return this.amountFormat(this.percentFee * this.amount).toLocaleString('en-US')
    },

    amountValidate() {
      return !!(this.amount >= this.minTopUp && this.amount <= this.maxAmount)
    },

    maxAmount() {
      return 100000
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-container {
  padding: 20px;
  background-color: #F5F5F7;
  // border: 1px solid rgba(22, 33, 62, 0.1);
  border-radius: 16px;

  .title-summary{
    margin-bottom: 21px;
  }
}

.total-amount{
  color: #27a27a;
}
</style>
