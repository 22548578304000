<template>
  <div
    class="bg-white list-card p-2"
    :class="hasStepGuide ? 'top-up-on-step' : 'top-up-form-container'"
  >
    <!-- topup -->
    <div
      data-testid="section-add-fund-usdt-form"
      class="add-fund-form"
    >
      <validation-observer
        ref="amountValidation"
        v-slot="{ invalid }"
      >
        <b-form
          data-testid="amount-usdt-form"
          class="amount-form"
          @submit.prevent=""
        >
          <!-- Setup cost-->
          <setup-cost
            v-if="hasStepGuide && isSetupCostModel"
            :amount="paymentAmount"
          />
          <!-- /Setup cost-->

          <div v-else>
            <div class="d-block d-md-flex justify-content-between align-items-center">
              <h3 data-testid="title-add-amount-usdt">
                {{ $t('stepGuideControl.textAddFund') }}
              </h3>
            </div>
            <p>{{ $t('payment.creditCard.textEnterAmount') }}</p>
            <b-form-group
              :label="$t('balance.textAmount')"
              class="position-relative form-label"
            >
              <validation-provider
                #default="{ errors }"
                id="amount-usdt"
                :name="$t('balance.textAmount')"
                :rules="{
                  required,
                  integerNumber,
                  greaterThanZero,
                  rangeTopUp: { min: minTopUp },
                }"
              >
                <b-input-group
                  class="input-group-merge width-custom w-100"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="DollarSignIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model.number="paymentAmount"
                    id="input-usdt-amount"
                    data-testid="input-usdt-amount"
                    class="input-height"
                    autofocus
                    type="number"
                    placeholder="EX: 2500"
                    :state="errors.length > 0 ? false : null"
                    @input="onChangeCurrentPaymentAmount"
                  />

                  <b-input-group-append
                    v-if="paymentAmount === maxAmount"
                    is-text
                  >
                    <img
                      :src="
                        require('@/assets/images/pages/payment/ic-max.svg')
                      "
                      alt="image"
                    >
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="errors[0]"
                  id="error-amount-usdt"
                  data-testid="error-amount-usdt"
                  class="text-danger d-block"
                  style="margin-top: 10px"
                >
                  {{ errors[0] }}
                </small>
                <small
                  v-if="!errors[0] && paymentAmount"
                  id="amount-valid-usdt"
                  data-testid="amount-valid-usdt"
                  class="d-flex align-items-center"
                  style="margin-top: 10px"
                >
                  <b-img
                    class="icons-tick"
                    fluid
                    style="margin-right: 5px"
                    :src="require('@/assets/images/icons/icon-tick.svg')"
                    alt="guide"
                  />
                  {{ $t('payment.textThisAmountIsSuitable') }}
                </small>
                <div class="info-box d-flex align-items-center">
                  <feather-icon
                    v-b-tooltip.v-light="{id: 'tooltip-usdt-limit-amount', title: `${$t('payment.creditCard.textAvailableLimit')}: $100,000`}"
                    icon="HelpCircleIcon"
                    size="12"
                    class="icon-info"
                    data-testid="usdt-limit-amount-note"
                  />
                </div>
              </validation-provider>
            </b-form-group>
            <div class=" d-flex align-items-md-center flex-wrap max-width">
              <div
                v-for="(item, index) in amountItem"
                :key="index"
                :id="`usdt-amount-${item.value}`"
                :data-testid="`usdt-amount-${item.value}`"
                class="btn-amount"
                :class="{ active: currentPaymentAmount.value === item.value }"
                @click="() => choosePaymentAmount(item)"
              >
                <span>${{ item.value }}</span>
              </div>
            </div>
          </div>
          <p
            class="mt-1 mb-0"
            data-testid="usdt-exchange-rate"
          >
            ~ 1 USD = 1 {{ paymentMethod.value }}
          </p>
          <div class="mt-2">
            <slide-step
              class="mt-2"
              :step="1"
            />
            <div class="d-flex justify-content-between font-14 font-medium mb-2">
              <span data-testid="content-step1-usdt">
                {{ $t('payment.USDT.contentSlideStep1', { paymentMethod: 'USDT' }) }}
              </span>
              <span>1/2</span>
            </div>
          </div>

          <div
            v-if="hasAMId && tipMode"
            class="mt-2"
          >
            <b-form-checkbox
              v-model="showTipsForAM"
              :aria-expanded="showTipsForAM ? 'true' : 'false'"
              aria-controls="collapse-1-inner"
              data-testid="checkbox-show-tips-input"
              @input="handleShowTipsInput"
            >
              <span class="cursor-pointer">{{ $t('payment.txtCheckboxShowTipsForAM') }}</span>
            </b-form-checkbox>
          </div>

          <b-collapse
            v-model="showTipsForAM"
            id="collapse-1-inner"
            class="mt-2"
          >
            <b-form-group
              class="position-relative form-label"
              label-for="tip-amount"
            >
              <validation-provider
                #default="{ errors }"
                id="tip-usdt"
                name="tip"
                :rules="{
                  positive,
                  rangeTipAmount: { min: minTipAmount },
                }"
              >
                <b-input-group
                  class="input-group-merge width-custom w-100"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="DollarSignIcon" />
                  </b-input-group-prepend>
                  <cleave
                    v-model.number="tipAMAmount"
                    id="usdt-tip-amount"
                    data-testid="usdt-tip-amount"
                    class="form-control"
                    :placeholder="$t('payment.creditCard.placeholderTip')"
                    :options="cleaveOptions"
                    :state="errors.length > 0 ? false : null"
                    @keyup.native="onChangeTipAmount"
                  />
                </b-input-group>
                <small
                  v-if="errors[0]"
                  id="error-tip-amount-usdt"
                  data-testid="error-tip-amount-usdt"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
            <div
              class="
              d-flex
              align-items-md-center
              flex-wrap
              max-width
            "
            >
              <div
                v-for="(item, index) in tipItem"
                :key="index"
                :id="`usdt-tip-item-${index+1}`"
                :data-testid="`usdt-tip-item-${index+1}`"
                class="btn-tip"
                :class="{ active: currentTipIndex === index+1 }"
                @click="chooseTipAmount({item, index})"
              >
                <span>{{ item.value === $t('payment.textNoTip') ? item.value : `${item.value}%` }}</span>
              </div>
            </div>
          </b-collapse>

          <div class="d-flex justify-content-end">
            <div v-b-tooltip.v-light="{id: 'tooltip-topup-usdt', title: `${isDisabledButton ? $t('stepGuideControl.stepAddFund.textWaitAdminVerify') : ''}`}">
              <btn-loading
                id="trigger-continue-usdt"
                data-testid="trigger-continue-usdt"
                span-id="trigger-continue-usdt-child"
                class="d-block mt-2 btn-height btn-text-color"
                variant-convert="btn-submit"
                pill
                :disabled="invalid || isDisabledButton"
                :loading="loading"
                @click="handleClickContinue"
              >
                {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textContinue') }}
              </btn-loading>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
    <!-- /topup -->

    <!-- Horizontal-divide -->
    <div
      class="line-center"
      md="1"
    >
      <div class="horizontal-divide" />
    </div>
    <!-- /Horizontal-divide-->

    <div
      data-testid="summary-step1-usdt-form"
      class="summary-form"
    >
      <summary-amount
        :amount="paymentAmount || amount"
        :payment-method="paymentMethod"
        :transfer="transfer"
        :amount-tip="tipAMAmount || 0"
        :current-tip="currentTip || 0"
        :show-tips-input="showTipsForAM"
        :transaction-type="transactionType"
      />
    </div>
  </div>
</template>

<script>
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BForm,
  BImg,
  VBTooltip,
  BInputGroupAppend,
  BFormCheckbox,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import Cleave from 'vue-cleave-component'

import paymentMixin from '@/mixins/paymentMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import billingMixin from '@/mixins/billingMixin'

import { PAYMENT_CHILD_TYPE, PERCENT_TIP_AMOUNT } from '@/constants'

// plugins
import { createNamespacedHelpers } from 'vuex'
// validation
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  greaterThanZero,
  required,
  rangeTopUp,
  integerNumber,
  rangeTipAmount,
  positive,
} from '@validations'
import _get from 'lodash/get'
import SetupCost from '@/views/payment/components/SetupCost.vue'
import SlideStep from '../SlideStep.vue'
import SummaryAmount from './Summary.vue'

const { mapGetters } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')
const { mapGetters: mapGettersAds } = createNamespacedHelpers('adsAccount')

export default {
  components: {
    SetupCost,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BImg,
    BInputGroupAppend,
    BFormCheckbox,
    BCollapse,
    BtnLoading,
    SlideStep,

    // validation
    ValidationProvider,
    ValidationObserver,
    SummaryAmount,
    Cleave,
  },

  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },

  mixins: [paymentMixin, generalConfigsMixin, numberFormatMixin, billingMixin, stepGuideMixin],

  props: {
    paymentMethod: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },

    transfer: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },

    amount: {
      type: Number,
      required: true,
    },

    amountTip: {
      type: Number || null,
      default: null,
    },

    tipIndex: {
      type: Number,
      default: 0,
    },

    percentageTip: {
      type: Number || null,
      default: null,
    },

    showTipsInput: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showTipsForAM: this.showTipsInput || false,
      tipItem: PERCENT_TIP_AMOUNT,

      // validation
      required,
      greaterThanZero,
      rangeTopUp,
      integerNumber,
      rangeTipAmount,
      positive,

      paymentAmount: this.amount === 0 ? '' : this.amount,
      currentPaymentAmount: {
        value: this.amount === 0 ? '' : this.amount,
        type: PAYMENT_CHILD_TYPE.NUMBER,
      },

      adAccount: {},
      // isToggle: null,

      currentTipIndex: this.tipIndex || null,
      currentTip: this.percentageTip || null,
      tipAMAmount: this.amountTip !== null ? this.amountTip : null,

      cleaveOptions: {
        numeral: true,
        numeralPositiveOnly: true,
        numeralThousandsGroupStyle: 'none',
      },
      minTipAmount: 0,

      transactionType: '',
      isDisabledButton: false,
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading', 'listTicket']),
    ...mapGettersAuth(['user']),
    ...mapGettersAds(['listAccount']),

    listAccounts() {
      return _get(this.listAccount, ['content'], [])
    },

    isInTrial() {
      return this.user?.isInTrial
    },

    maxAmount() {
      return 100000
    },

    checkHasNextBtn() {
      return this.hasStepGuide && this.user?.data?.balance > 0
    },
  },

  watch: {
    listTicket: {
      handler(val) {
        if (val) {
          this.isDisabledButton = this.isSetupCostModel && this.hasStepGuide && !!val?.content?.find(item => item.status === 'opening')
        }
      },
      deep: true,
      immediate: true,
    },

    user: {
      async handler(currentUser) {
        this.handleSetupCost()
        if (currentUser?.data?.onboardingStep) {
          this.isDisabledButton = this.isSetupCostModel && currentUser?.data?.onboardingStep === 5
        }
      },
      deep: true,
      immediate: true,
    },

    generalConfigs: {
      handler(val) {
        if (val) {
          this.handleSetupCost()
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    handleSetupCost() {
      if (this.hasStepGuide && this.isSetupCostModel) {
        this.paymentAmount = this.generalConfigs?.setupCostFee
        this.transactionType = 'setup_cost'
      }
    },

    handleShowTipsInput() {
      if (!this.showTipsForAM) {
        this.tipAMAmount = null
        this.onChangeTipAmount()
      }
    },

    onChangeTipAmount() {
      this.currentTipIndex = null
      this.currentTip = null
    },

    onChangeCurrentPaymentAmount() {
      if (this.currentTip) {
        this.tipAMAmount = ((this.currentTip * this.paymentAmount) / 100)
      }
      this.currentPaymentAmount = {
        value: this.paymentAmount,
        type: PAYMENT_CHILD_TYPE.NUMBER,
      }
    },

    choosePaymentAmount(data) {
      this.paymentAmount = data.value
      this.currentPaymentAmount = data

      if (this.currentTip) {
        this.tipAMAmount = ((this.currentTip * data.value) / 100)
      }

      this.$nextTick(() => {
        this.$refs.amountValidation.validate()
      })
    },

    chooseTipAmount(data) {
      this.currentTip = data.item.value === this.$t('payment.textNoTip') ? null : data.item.value
      this.tipAMAmount = ((this.currentTip * this.paymentAmount) / 100)
      this.currentTipIndex = data.index + 1
    },

    handleClickContinue() {
      if (this.noPlan) {
        this.$router.push('/billing')
      } else {
        if (!this.paymentAmount) {
          return
        }

        this.$emit('on-confirm', {
          amount: this.paymentAmount,
          account: this.adAccount,
          tipAMAmount: this.tipAMAmount,
          currentTip: this.currentTip,
          currentTipIndex: this.currentTipIndex,
          showTipsForAM: this.showTipsForAM,
          transactionType: this.transactionType,
          // isToggle: this.isToggle,
        })
      }
    },

    // handleSelectAdAccount(data) {
    //   this.adAccount = data?.account
    //   this.isToggle = data.isToggle
    // },
  },
}
</script>
<style lang="scss">
.is-invalid {
  #tip-amount:not(:focus) {
    border-color: #e11c4e !important;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";
.top-up-on-step {
  .line-center {
    display: none;
  }
}

.top-up-form-container{
  display: flex;

  .add-fund-form{
    max-width: 50%;
  }

  .line-center{
    margin: 0 1.5rem;
  }

  .summary-form{
    width: 50%;
  }

  @media (max-width: 1399px){
    display: block;

    .add-fund-form{
      max-width: 100%;
    }

    .line-center{
      display: none;
    }

    .summary-form{
      width: 100%;
    }
  }
}

.btn-amount, .btn-tip {
  padding: 6px 12px;
  margin-right: 10px;
  margin-top: 10px;

  border: 1px solid #e0e0e5;
  background-color: white;
  border-radius: 6px;

  cursor: pointer;

  color: #3d405b;
  font-size: 14px;

  &:hover,
  &.active {
    border: 1px solid $primary;
    color: $primary;
    font-weight: 600;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}

.max-width {
  max-width: 410px;
}

.form-label {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 600;
}

.list-card {
  border-radius: var(--border-radius-base);
}
</style>
