<template>
  <div class="pb-3">
    <div v-if="detailInfo">
      <div :class=" hasStepGuide ? 'in-process-step-guide' : 'top-up-in-process'">
        <top-up-success
          class="summary-form"
          :detail-info="detailInfo"
          :amount="detailInfo.amount"
          :transfer="{value: detailInfo.coinNetwork}"
          :coin-type="detailInfo.coinType"
          :payment-method="{value: detailInfo.coinType, paymentMethod: detailInfo.paymentMethod}"
        />
      </div>
      <div
        class="user-hint mt-1 mb-2 d-flex"
        :class="hasStepGuide ? '' : 'justify-content-center'"
      >
        <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
      </div>
    </div>

    <div
      v-else-if="!showConfirmation"
      class="width-content"
    >
      <b-row
        class="add-fund-container mx-0"
      >
        <b-col
          :md="hasStepGuide ? 12 : 9"
          class="p-0 card-content"
        >
          <div
            class="wise-top-up-amount p-0"
            @mouseover="showGuideDefault = false"
            @mouseleave="showGuideDefault = true"
          >
            <top-up-amount
              :payment-method="paymentMethod"
              :amount="amount"
              :amount-tip="amountTip"
              :tip-index="currentTipIndex"
              :show-tips-input="showTipsForAM"
              @on-confirm="handleShowConfirm"
            />
          </div>
        </b-col>
        <b-col
          v-if="!hasStepGuide"
          cols="12"
          md="9"
          :lg="hasStepGuide ? 12 : 3"
          class="payment-user-guide card-content"
        >
          <user-guide v-if="showUserGuide" />
        </b-col>
      </b-row>
      <div class="user-hint mt-1 mb-2 d-flex">
        <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
      </div>
    </div>
    <div
      v-else
      class="width-content"
    >
      <b-row class="add-fund-container mx-0">
        <b-col
          :md="hasStepGuide ? 12 : 9"
          class="card-content p-0"
        >
          <div
            class="wise-confirm-amount p-0"
            @mouseover="showGuideDefault = false"
            @mouseleave="showGuideDefault = true"
          >
            <!-- :is-toggle="isToggle" -->
            <confirm-amount
              :wise-payment-info="wisePaymentInfo"
              :transaction-info="transactionInfo"
              :amount="amount"
              :account="account"
              :billing="billing"
              :amount-tip="amountTip"
              :current-tip="currentTip"
              :payment-method="paymentMethod"
              :show-tips-input="showTipsForAM"
              @on-prev="handlePrev"
              @handleShowSuccess="handleShowSuccess"
            />
          </div>
        </b-col>

        <b-col
          v-if="!hasStepGuide"
          cols="12"
          md="9"
          :lg="hasStepGuide ? 12 : 3"
          class="payment-user-guide card-content"
        >
          <user-guide v-if="showUserGuide" />
        </b-col>
      </b-row>
      <div class="user-hint mt-1 mb-2 d-flex">
        <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { PAYMENT_METHOD } from '@/constants'
import envMixin from '@/mixins/envMixin'
import { createNamespacedHelpers } from 'vuex'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import authMixin from '@/mixins/authMixin'
import UserHint from '@/components/UserHint.vue'
import UserGuide from '@/components/UserGuide.vue'
import paymentMixin from '@/mixins/paymentMixin'
import TopUpAmount from './TopUpAmount.vue'
import ConfirmAmount from './ConfirmAmount.vue'
import TopUpSuccess from './TopUpSuccess.vue'

const { mapGetters } = createNamespacedHelpers('payment')

export default {
  components: {
    BRow,
    BCol,
    TopUpAmount,
    ConfirmAmount,
    TopUpSuccess,
    UserHint,
    UserGuide,
  },

  mixins: [envMixin, stepGuideMixin, authMixin, paymentMixin],

  data() {
    return {
      paymentMethod: {},
      showConfirmation: false,
      amount: 0,
      account: {},
      // isToggle: null,
      transactionInfo: '',
      detailInfo: null,
      billing: [],
      showIntro: false,
      isShowSuccessPage: false,

      showGuideDefault: true,
      contentModal: {},
      wisePaymentModal: {
        title: 'How to add fund with Wise?',
        description: '',
        video: 'https://www.youtube.com/embed/YGJ2J1FVcc8',
        guideURL: process.env.VUE_APP_GUIDE_TO_TOP_UP_WISE,
      },
      wisePaymentModalNoStep: {
        title: 'How to add fund with Wise?',
        description: '',
        video: 'https://www.youtube.com/embed/p9hcw_sbT30',
        guideURL: process.env.VUE_APP_GUIDE_TO_TOP_UP_WISE,
      },

      currentTip: null,
      amountTip: null,
      currentTipIndex: null,
      showTipsForAM: false,
    }
  },
  computed: {
    ...mapGetters(['listTicket']),
    wisePaymentInfo() {
      return this.paymentInfo.transferWisePaymentLink.info
    },
  },

  watch: {
    listTicket: {
      handler(val) {
        if (val) {
          const itemDetail = val?.content?.find(item => item?.details?.paymentMethod === PAYMENT_METHOD.TRANSFERWISE_LINK && item.status === 'opening')
          this.detailInfo = itemDetail?.details
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    handleShowConfirm(data) {
      this.showConfirmation = true
      this.amount = data.amount
      this.account = data.account
      this.currentTip = data.currentTip
      this.amountTip = data.tipAMAmount
      this.currentTipIndex = data.currentTipIndex
      this.showTipsForAM = data.showTipsForAM
      // this.isToggle = data.isToggle
    },

    handlePrev(data) {
      this.showConfirmation = false
      this.amount = data.amount
      this.transactionInfo = data.transactionId
      this.billing = data.billing
      this.currentTip = data.currentTip
      this.amountTip = data.amountTip
      this.showTipsForAM = data.showTipsForAM
    },

    handleShowSuccess(data) {
      this.isShowSuccessPage = data
      this.$emit('handleShowSuccessTabs', this.isShowSuccessPage)
    },
  },
}
</script>
<style lang="scss">

.horizontal-divide{
  width: 1px;
  height: 100%;
  background: #EEEEEE;
}

.info-box{
  position: absolute;
  top: -23px;
  right: 3px;
  font-size: .875rem;
  .icon-info{
    margin-left: 4px;
  }
}

.mrb-10 {
  margin-bottom: 10px;
}

.top-up-in-process {
  max-width: 410px;
  margin: 0 auto;
  margin-top: 42px;
}

.width-content {
  max-width: 1200px;
  margin: 0px auto;

  @media (max-width: 992px){
    justify-content: center;
  }
}

.guide-video {
  position: absolute;
  top: 19px;
  left: 20px;
  color: #4CB375;
}

.guide-icon {
  top: 19px;
  right: 12px;
}

.wise-payment-info:hover .tooltip-wise-payment-info {
  @media (min-width: 992px) {
    display: block;
  }
}
.wise-top-up-amount:hover .tooltip-wise-top-up-amount {
  @media (min-width: 992px) {
    display: block;
  }
}
.wise-confirm-amount:hover .tooltip-wise-confirm-amount {
  @media (min-width: 992px) {
    display: block;
  }
}

.card-content{
  @media (max-width: 1399px) {
    max-width: 719px;
  }
}
</style>
<style lang="scss" scoped>
  .warning-message {
    margin-top: 10px;
    background: rgba(251, 176, 59, 0.1);
    border-radius: 10px;
    padding: 10px 12px 10px 16px;
  }

  .alert-img {
    width: 16px;
    margin-top: 6px;
  }

  .width-warning {
    margin: 0 auto;
    max-width: 900px;
  }
</style>
