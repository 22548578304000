<template>
  <div>
    <b-row class="align-items-center">
      <b-col
        md="10"
        cols="10"
        class="d-flex flex-column"
      >
        <div
          v-if="!isBinanceID && !isRedotpayID"
          class="content-usdtWallet"
        >
          <label class="font-14">{{ $t('payment.USDT.labelWalletAddress', { value: 'USDT' }) }}</label>
          <p
            class="text-usdtWallet"
            data-testid="usdt-wallet-address"
          >
            {{ usdtWallet }}
          </p>
        </div>
        <div v-else>
          <p
            v-if="transfer.userName"
            class="mb-0"
          >
            <strong class="font-weight-700">{{ $t('common.labelName') }}:</strong> {{ transfer.userName }}
          </p>
          <p class="mb-0">
            <strong class="font-weight-700">{{ transfer.value }}: </strong>{{ transfer.add }}
          </p>
        </div>
      </b-col>

      <b-col
        md="2"
        cols="2"
        class="text-right"
      >
        <b-img
          class="mr-2 tool"
          fluid
          alt="img"
          data-testid="usdt-copy-wallet-address-btn"
          :src="require('@/assets/images/pages/payment/ic-copy.svg')"
          @click="handleCoppy"
        />
        <b-popover
          target="popover-target-1"
          triggers="hover"
          placement="top"
        >
          <div class="qbarcode">
            <p class="wallet-id text-center">
              {{ usdtWallet }}
            </p>
          </div>
        </b-popover>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BImg, BPopover,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BPopover,
  },

  mixins: [toastification],

  props: {
    transfer: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },

  computed: {

    isBinanceID() {
      return this.transfer.value === 'Binance User ID'
    },

    isRedotpayID() {
      return this.transfer.value === 'Redotpay ID'
    },

    usdtWallet() {
      return this.transfer.add
    },

    barCode() {
      return (
        // eslint-disable-next-line global-require
        process.env.VUE_APP_USDT_BAR_CODE || require('@/assets/images/pages/payment/qbarcode.svg')
      )
    },
  },

  methods: {
    handleCoppy() {
      window.navigator.clipboard.writeText(this.usdtWallet).then(() => {
        this.toastSuccess(this.$t('payment.contentCopiedId'))
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.content-usdtWallet {
  overflow-wrap: break-word;
  width: 100%;
}
.text-usdtWallet {
  font-weight: 600;
}
.input {
  border: none;
}

.tool {
  cursor: pointer;
}
.qbarcode {
  width: 147px;
  margin-bottom: 14px;
}

.wallet-id {
  font-size: 12px;
  line-height: 1.2;
}
</style>
